@import 'styles/utils';

.wrapper {
  padding: 50px 0;
}

.title.title {
  display: block;
  line-height: 2rem;
  margin-bottom: 15px;
  @include breakpoint('lg') {
    line-height: 56px;
    margin-bottom: 20px;
  }
}

.image {
  width: calc(100% - 10px);
  height: auto;
  object-fit: contain;
  border-radius: 4px;
  margin-bottom: 35px;
  margin-top: 35px;
  @include breakpoint('lg') {
    margin-bottom: 0;
  }
}

.markdown p {
  line-height: 1.5rem;
  margin-bottom: 15px;
  @include breakpoint('lg') {
    margin-bottom: 20px;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
}
